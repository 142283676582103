.button {
  @apply
    tw-m-0
    tw-flex
    tw-justify-center
    tw-items-center
    tw-rounded-4-px
    tw-p-16-px
    tw-text-14-px
    tw-font-700
    tw-leading-1.25
    tw-uppercase
    tw-text-center
    tw-border-0;

  .p-button-label {
    @apply
      tw-flex-none;
  }
}

.p-button {

  &:focus {
    @apply
      tw-shadow-none;
  }

}

.button-primary {
  @apply
    tw-text-16-px
    tw-bg-button
    tw-text-btn-primary-color;

  &:disabled {
    @apply
      tw-text-btn-primary-disabled/100
  }

  &:enabled {

    &:hover {
      box-shadow: 0px 0px 0 3px #012169 inset, 0 0 0 4px #fff inset;

      @apply
        tw-bg-button
        tw-text-btn-primary-color;
    }
  }

  &:active {
    @apply
      tw-bg-button
      tw-text-btn-primary-color;
  }
}

.button-primary-bg {
  @apply
    tw-text-16-px
    tw-bg-primary;

  &:disabled {
    @apply
      tw-text-btn-primary-disabled/100
  }

  &:focus {
    @apply
      tw-shadow-none;
  }

  &:enabled {

    &:hover {
      @apply
        tw-bg-primary
    }
  }
}

.button-google {

  .p-button-label {
    @apply
      tw-text-14-px
      tw-text-text-color-1
      tw-tracking-.4-px
      tw-font-500
      tw-py-16-px
      tw-pl-8-px
      tw-flex-none
      tw-capitalize;
  }
}

.reset-password-container {

  .p-button-label {
    @apply
      tw-text-16-px
  }
}
